import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./HarmonicRoots.css";
import "../theme.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

function HarmonicRoots(props) {
  return (
    <React.Fragment>
      <Nav />
      <section id="hero-harmonic" class="hero">
          <div class="inner-header">
            <div class="copy">
              <h1 className="home-header">HARMONIC ROOTS</h1>
            </div>
          </div>
        </section>
      <div className="container offerings-container">
        
        <div className="content-wrapper">
          <h2 className="harmonic-text">Looks like you’ve stumbled upon our roots!
</h2>
<h2 className="harmonic-text">Gina, Everest, Kolohe, Dave and Max form the core of this improvisational music group. Our primary intentions are to heal and empower the earth and all its creatures through vibration. We are excited to offer a program called ‘Art Church’ which is designed to bring individuals together in communal self-expression. <br></br> <br></br> We provide two services every Sunday, at our space in Massapequa, New York. During the first service, participants are encouraged to simply relax and receive the healing vibrations. The second service is paired with a form of art (like painting) or movement (like yoga or dance) to encourage participants to express themselves and help cocreate the collective experience. In between these services we offer a nourishing family meal. The energy exchange for each service is $20 and $10 for dinner - dinner is free if you choose to attend both services or bring a potluck dish. Please send us an email or direct message or for more information.<br></br> <br></br> We also offer extended sound ceremonies with plant teachers and private concerts upon request.</h2>
<h2 className="harmonic-text">Grow with us:</h2>
<div className="footer-icons">
              <a href="mailto:harmonicroots639@gmail.com" target="_blank">
                <i className="fa fa-envelope footer-icon"></i>
              </a>
              <a href="https://www.facebook.com/harmonicrootscollective" target="_blank">
                <i className="fa fa-facebook footer-icon"></i>
              </a>
              <a
                href="https://www.instagram.com/harmonicrootscollective/"
                target="_blank"
              >
                <i className="fa fa-instagram footer-icon"></i>
              </a>
              <a href="https://soundcloud.app.goo.gl/yrEJ" target="_blank">
                <i className="fa fa-soundcloud footer-icon"></i>
              </a>
              <a href="https://open.spotify.com/artist/3n69JxDK1kIO9fUgsJbzlK?si=VFV9379dSIa-CyJEjqE4Zg" target="_blank">
                <i className="fa fa-spotify footer-icon"></i>
              </a>
              
            </div>

          
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default HarmonicRoots;
