import React from "react";
import About from "../components/About/About";
import Home from "../components/Home/Home";
import Contact from "../components/Contact/Contact";
import Work from "../components/Work/Work";
import Offerings from "../components/Offerings/Offerings";
import HarmonicRoots from "../components/HarmonicRoots/HarmonicRoots";

const routes = {
  "/": () => <Home />,
  "/about": () => <About />,
  "/contact": () => <Contact />,
  "/work": () => <Work />,
  "/offerings": () => <Offerings />,
  "/harmonicroots": () => <HarmonicRoots />,
};
export default routes;
