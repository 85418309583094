import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Home.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import Link from "react";

function Home(props) {
  return (
    <React.Fragment>
      <Nav />

      <div className="careers-container work-container">
        <section id="hero-home" class="hero">
          <div class="inner-header">
            <div class="copy">
              <h1 className="home-header">G I V E SOUNDS</h1>
            </div>
          </div>
        </section>

        <section class="content">
          <div class="inner">
            <div class="">
              <div className="home-text-container">
                <p className="home-title">
                  Join multi-instrumentalists Gina Marchese and Everest Bond on
                  a journey through lush, sonic spaces among Earth's most
                  beautiful places. The duo is currently New York based,
                  offering weekly public events and private sessions / lessons
                  upon request.
                </p>
              </div>
              <div className="home-video-container">
                <iframe
                  className="home-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/8lPz7yg9hs8"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Home;
