import React, { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.css";
import "./Contact.css";
import "../theme.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import contactImg from "../../resources/email.svg";
// import FA from "react-fontawesome";

function Contact(props) {
  return (
    <React.Fragment>
      <Nav />
      <section id="hero-contact" class="hero">
          <div class="inner-header">
            <div class="copy">
              <h1 className="home-header">CONTACT</h1>
            </div>
          </div>
        </section>
      <div className="container contact-container">
        
        <div className="content-wrapper">
          {/* <img src={contactImg} className="center-image" /> */}

          {/* <h1 className="contact-header">CONTACT</h1> */}
          <div className="contact-info">
            <h2 className="contact-subheader">EMAIL</h2>
            <p>
              {" "}
              <a href="mailto:givesounds@gmail.com"> givesounds@gmail.com</a>
            </p>
            {/* <h2 className="contact-subheader">PHONE</h2>

            <p>
              Everest: <a href="tel:631-678-3462">631-678-3462</a>
            </p>

            <p>
              Gina: <a href="tel:516-780-4408">516-780-4408</a>
            </p> */}
            <div className="footer-icons">
              <a href="https://www.facebook.com/givesounds" target="_blank">
                <i className="fa fa-facebook footer-icon"></i>
              </a>
              <a href="https://soundcloud.com/givesounds" target="_blank">
                <i className="fa fa-soundcloud footer-icon"></i>
              </a>
              <a
                href="https://www.instagram.com/givesounds/?hl=en"
                target="_blank"
              >
                <i className="fa fa-instagram footer-icon"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Contact;
