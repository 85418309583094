import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Footer.css";
import cubeLogo from "../../resources/3d-cube.svg";

function Footer(props) {
  return (
    <React.Fragment>
      <div className="footer-container">
        <div className="footer-wrapper">
          {/* <img src={cubeLogo} className="footer-company-icon"></img> */}
          <p className="footer-company-name">G I V E SOUNDS</p>

          {/* <div className="footer-links">
            <p className="footer-links-title">Links</p>
            <a href="/" className="footer-link">
              <p>Home</p>
            </a>
            <a href="/about" className="footer-link">
              <p>About</p>
            </a>
          </div> */}

          <div className="footer-icons">
            <a href="https://open.spotify.com/artist/3n69JxDK1kIO9fUgsJbzlK?si=VFV9379dSIa-CyJEjqE4Zg" target="_blank">
              <i className="fa fa-spotify footer-icon"></i>
            </a>
            <a href="https://music.apple.com/us/artist/give-sounds/1535894829" target="_blank">
              <i className="fa fa-apple footer-icon"></i>
            </a>
            <a href="https://www.facebook.com/givesounds/" target="_blank">
              <i className="fa fa-facebook footer-icon"></i>
            </a>
            <a href="https://soundcloud.com/givesounds?ref=sms&p=a&c=0" target="_blank">
              <i className="fa fa-soundcloud footer-icon"></i>
            </a>
            <a href="https://instagram.com/givesounds?igshid=a5q9znvyg36h" target="_blank">
              <i className="fa fa-instagram footer-icon"></i>
            </a>
          </div>
          <p className="footer-text">&copy; G I V E SOUNDS</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
