import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Offerings.css";
import "../theme.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

function Offerings(props) {
  return (
    <React.Fragment>
      <Nav />
      <section id="hero-offerings" class="hero">
          <div class="inner-header">
            <div class="copy">
              <h1 className="home-header">OFFERINGS</h1>
            </div>
          </div>
        </section>
      <div className="container offerings-container">
        
        <div className="content-wrapper">
          
          <h2 className="offerings-header">
            Vibration Meditations for Private Groups
          </h2>
          <ul className="offerings-list">
            <li>
              An immersive sound bath co-created with intentions of the group.
              We will sculpt a soundscape for you to relax and release in as we
              hold the space and assist in your rejuvenation and restoration
              process.
            </li>
            <li>
              Base rate $100 plus travel costs, length of session (60 or 90
              minutes) and number of participants.
            </li>
          </ul>
          <h2 className="offerings-header">
            Individual and couples sound healing{" "}
          </h2>
          <ul className="offerings-list">
            <li>
              Book a personal sound meditation co-created with your needs and
              intentions. This can assist as an energetic clearing for the mind,
              body, and soul.
            </li>
            <li>
              Base rate $120 plus travel / venue expenses and length of session
              (60 or 90 minutes).
            </li>
          </ul>
          <h2 className="offerings-header">
            Voice, guitar, and percussion lessons{" "}
          </h2>
          <ul className="offerings-list">
            <li>
              Join Gina for a vocal embodiment session as she will assist you in
              a vocal transformation. Cocreate intentions and goals for the
              lessons with her personally and learn how to sing technically
              healthy and soulfully embodied.
            </li>
            <li>
              Join Gina for guitar lessons, beginner and intermediate, as she
              will cocreate intentions and goals with you personally for the
              lessons. She can assist you in learning how to open creatively,
              read music, write music, and use the loop pedal.
            </li>
            <li>
              Join Everest for percussion lessons to remember your rhythm. Much
              like our heartbeat, we all carry rhythmic signatures that are
              unique to each and every one of us. Together you will co-create
              personal intentions and goals for each lesson. Everest can support
              you in learning percussive patterns, composing your own music, and
              understanding how to tap into a state of ‘flow’ while playing.
              Frame drums, tongue drums, handpans, kalimbas, and other
              percussive instruments may be utilized in this process. This is a
              great option for those who are new to music making and may not
              have a background in music theory.
            </li>
          </ul>
          <h2 className="offerings-header">Called to collaborate? </h2>
          <ul className="offerings-list">
            <li>
              Are you a practioner of a healing art such as yoga, reiki, or
              massage? Do you own or operate a business that could benefit from
              live music? If you feel the call to collaborate, please reach out!
              We would love to work with you.
            </li>
          </ul>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Offerings;
