import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Work.css";
import "../theme.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

import giveSounds1 from "../../resources/givesounds-1-fix.jpg";
import giveSounds2 from "../../resources/givesounds-2-fix.jpg";
import giveSounds3 from "../../resources/givesounds-3-fix.jpg";
import giveSounds4 from "../../resources/ev-6.jpg";
import giveSounds5 from "../../resources/ev-5.jpg";
import giveSounds6 from "../../resources/ev-4.jpg";
import giveSounds7 from "../../resources/ev-3.jpg";
import giveSounds8 from "../../resources/ev-2.jpg";
import giveSounds9 from "../../resources/ev-1.jpg";

function Work(props) {
  return (
    <React.Fragment>
      <Nav />

      <div className="container work-container">
        <div className="content-wrapper">
          {/* <img src={projectRocketIcon} className="center-image" /> */}
          <h1 className="custom-header">OUR WORK</h1>

          {/* <h2 className="info">
            kbd engineers take pride in the custom websites we develop, and we
            love to show them off.
          </h2> */}
          {/* <button className="contact-button">coming soon</button> */}

          <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light services-container">
            <div className="row">
              <div className="col-lg-4">
                
                  <img src={giveSounds1} className="work-img" />
                
                <a href="#" target="_blank">
                  {/* <h2 className="work-job-header">Bowties Bridal</h2> */}
                </a>
                {/* <p className="work-description">
                  Custom website, built from scratch with &hearts; for the best
                  bridal shop in town.
                </p> */}
              </div>
              <div className="col-lg-4">
                
                  <img src={giveSounds2} className="work-img" />
                
       
                {/* <p className="work-description">
                  Landing page for AR/VR startup.
                </p> */}
              </div>
              <div className="col-lg-4">
                
                  <img src={giveSounds3} className="work-img" />
                
                
                {/* <p className="work-description">
                  News aggregator with backend RSS Scraper.
                </p> */}
              </div>
              <div className="col-lg-4">
                
                  <img src={giveSounds4} className="work-img" />
                
                
                {/* <p className="work-description">
                  News aggregator with backend RSS Scraper.
                </p> */}
              </div>
              <div className="col-lg-4">
                
                  <img src={giveSounds7} className="work-img" />
                
                
                {/* <p className="work-description">
                  News aggregator with backend RSS Scraper.
                </p> */}
              </div>
              <div className="col-lg-4">
                
                  <img src={giveSounds8} className="work-img" />
                
                
                {/* <p className="work-description">
                  News aggregator with backend RSS Scraper.
                </p> */}
              </div>
              <div className="col-lg-4">
                
                  <img src={giveSounds5} className="work-img" />
                
                
                {/* <p className="work-description">
                  News aggregator with backend RSS Scraper.
                </p> */}
              </div>
              <div className="col-lg-4">
                
                  <img src={giveSounds6} className="work-img" />
                
                
                {/* <p className="work-description">
                  News aggregator with backend RSS Scraper.
                </p> */}
              </div>
              <div className="col-lg-4">
                
                  <img src={giveSounds9} className="work-img" />
                
                
                {/* <p className="work-description">
                  News aggregator with backend RSS Scraper.
                </p> */}
              </div>
              <div className="col-lg-12">
                <div>
                  <iframe
                    className="work-video"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/8lPz7yg9hs8"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                {/* <a href="http://kbradydavis.com/" target="_blank">
                  <img src={bradyScreenImage} className="work-img" />
                </a>
                <a href="http://kbradydavis.com/" target="_blank">
                  <h2 className="work-job-header">Personal Website</h2>
                </a> */}
                {/* <p className="work-description">
                  Personal website to showcase software engineering skills and
                  accomplishments (tap the head).
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Work;
